.SearchBar {
    width: 75vw;
}

.SearchBarContainer {
    width: 75vw;
    margin: auto;
    display: flex;
    border: 1px solid #E9EDEF;
    height: 5.2rem;
    border-right: none;
    align-items: center;
}

.SearchBar .ant-calendar-range-picker-separator {
    vertical-align: revert;
}

.SearchBar .ant-input {
    height: 5rem;
    border: none;
    padding: 0;
}

.SearchBar .calendar {
    border-right: 1px solid #E9EDEF;
    border-radius: 0;
}

.SearchBar .selectInput {
    border: none;
    width: 9.8vw;
}

.SearchBar .ant-select-selection {
    border: none;
    height: 5rem;
}

.SearchBar .placeInput {
    width: 20vw;
}

.SearchBar .ant-select-selection__rendered {
    height: 5rem;
}

.SearchBar .ant-select-selection__rendered {
    display: flex;
    align-items: center;
}

.SearchBar .ant-select-selection--single {
    border-right: 1px solid #E9EDEF;
    border-radius: 0;
}

.SearchBar .dropdownContainer {
    width: 30vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
}

/* MobileSearch */

.MobileSearchBar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem 1.75rem;
}

.MobileSearchBar .inputContainer {
    width: 100%;
    border: 1px solid #E9EDEF;
    padding: 1rem;
    margin-bottom: 2rem;
}

.MobileSearchBar .inputDescription {
    color: #bfbfbf;
    margin-bottom: 1rem;
    margin-top: 0rem;
    margin-left: 0rem;
}

.MobileSearchBar .RoomInfoContainer {
    display: flex;
}

/* .RoomInfoContainer .inputContainer {
    padding-bottom: 0;
} */

.MobileSearchBar .ant-select-selection {
    border: 0;
}

.MobileSearchBar .ant-select-selection__rendered {
    margin-left: 0;
}

.MobileSearchBar .ant-input {
    border: none;
}

.MobileSearchBar .selectInput {
    width: 100%;
}

.MobileSearchBar .ant-calendar-picker-icon {
    display: none;
}

/* RoomInfoMobile */

.RoomInfoMobile {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: white;
    justify-content: center;
}

.RoomInfoMobile .inputContainer {
    width: 100%;
    border: 1px solid #E9EDEF;
    padding: 1rem;
    margin-bottom: 2rem;
}

.RoomInfoMobile .ant-select-selection__rendered {
    border: none;
}

.RoomInfoMobile .ant-select-selection {
    border: none;
}

.RoomInfoMobile .ant-select-selection__rendered {
    margin-left: 0;
}

.RoomInfoMobile .inputContainer {
    padding-bottom: 0;
}

.RoomInfoMobile .RoomInfo {
    width: auto;
    position: relative;
    height: auto;
    min-height: auto;
    margin: 2rem 0;
    padding: 2rem 0;
    border-left: none;
    border-right: none;
}

.mobileDatepicker {
    display: flex;
    align-items: center;
}

.mobileDatepicker input {
    text-align: center;
}

.ant-calendar-header .ant-calendar-prev-year-btn,
.ant-calendar-header .ant-calendar-next-year-btn {
    display: none;
}
