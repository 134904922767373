.RoomPriceContainer {
    border: 1px solid #dae0ed;
    padding: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    width: 100%;
    margin-bottom: 1rem;
    /* background-color: #b8d0da; */
}

.roomSelect {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.blackroomType {
    background-color: #141414;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem 1rem;
    margin-right: 1rem;
    border-radius: 2px;
    font-size: 1rem;
    width: max-content;
}

.costRoom {
    color: #F48247;
}

.Roomloading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh;
}

.hrStyle {
    border-color: #dae0ed;
    margin: 2rem 0;
}
.RoomPriceContainer .horizontal {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}
@media screen and (min-width: 776px) {
    .RoomPriceContainer {
        width: calc(50% - 0.5rem);
        font-size: 1.5rem;
    }

    .RoomPriceContainer:nth-child(odd) {
        margin-left: 0;
        margin-right: 0.5rem;
    }

    .RoomPriceContainer:nth-child(even) {
        margin-left: 0.5rem;
        margin-right: 0;
    }
}
