.mainContainer {
    height: 100vh;
    width: 20vw;
    border-right: 1px solid #cfcfcf;

}

.sideMenu {
    display: flex;
    flex-direction: column;
    
}

.selected {
    background-color: black;
}

.button {
    width: 100%;
    height: 65px;
    /* background-color: blue; */
    border: 0;
    padding: 0;
}

.buttonPadding {
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px;
}

.flexColumn {
    display: flex;
    flex-direction: column;
}