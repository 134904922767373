
.descriptions{
    font-size: 1rem;
    margin: 1rem;
}

.descriptions heading {
    font-weight: bold;
}
@media screen and (min-width: 992px) {
    .descriptions{
        font-size: 2rem;
        margin: 1rem;
    }

    .descriptions heading {
        font-weight: bold;
    }
}
