.Header {
	display: flex;
	flex-direction: row;
	height: 8vh;
	width: 100vw;
	align-self: flex-start;

	background-color: transparent;
    align-items: center;

    /*border-bottom: 1px solid #E9EDEF;*/
}

.Header .innercontainer {
    margin: auto;
    display: flex;
    align-items: center;
    width: 90vw;
}

.Header .logo {
    margin-right: auto;
    background-size: contain;
    background-image: url("./../../images/Home/logo-full.png");
    background-repeat: no-repeat;
    height: 10vh;
    width: 25vh;
}

.Header .logo-yoodo {
    margin-right: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("./../../images/provider/partner_logo.png");
    /*background-image: url("./../../images/provider/partner_yoodo.png");*/
    height: 12vh;
    width: 45vw;
    margin-top: 10px;
}
.Header .logo-yoodo.white {
    background-image: url("./../../images/provider/partner_logo_w.png");
}

.Header .buttonContainer {
    display: flex;
    align-items: center;
    width: auto;
}
.Header .button{
    font-family: 'Inter', sans-serif;
    background-color: transparent;
    background: transparent;
    height: auto;
    width: auto;
    margin-left: 2rem;
    border-color: transparent;
}

.Header .button:hover{
    cursor: pointer;
}


.Header .welcome {
    font-family: 'Inter';
    font-weight: 700;
}

@media screen and (min-width:776px){
    .Header .innercontainer {
        width: 75vw;
    }
}
