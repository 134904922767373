.carouselContainer {
  height: 80vw;
  /* display: flex !important; */
  /* justify-content: center;
  align-items: center; */
}

.carouselContainerDots {
  background-color: rgba(235, 35, 74, 0.1);
  border-radius: 5rem;
  margin: 1rem;
  height: 1rem;
  width: 1rem;
}

.ant-carousel .slick-dots>li>button {
  background-color: rgba(235, 35, 74, 0.3);
  border-radius: 5rem;
  margin: 1rem;
  height: 1rem;
  width: 1rem;
}

.ant-carousel .slick-dots .slick-active>button {
  background: #eb234a;
}

.smallImage {
  height: 60vw;
}

.AccommodationDetailsMobile .information {
  padding: 1.75rem;
}

.AccommodationDetailsMobile .mainHeading {
  font-size: 2rem;
  width: auto;
}

.AccommodationDetails .roomInfo {
  display: flex;
  font-size: 1.25rem !important;
  align-items: center;
  margin: 1rem 0;
}

.AccommodationDetails .Searchstars {
  font-size: 1rem !important;
  color: #d93b4f;
}

.AccommodationDetails .SearchResultsRoomType {
  background-color: #141414;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  margin-right: 1rem;
  border-radius: 2px;
}

.AccommodationDetails .grayInfo {
  font-weight: 400;
  color: #858586;
}

.AccommodationDetailsMobile .subHeading {
  font-weight: bold;
  margin-top: 2rem;
}

.image-gallery {
  width: auto;
  height: 59vw;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: 59vw;
}

.image-gallery-bullets .image-gallery-bullet.active {
  background-color: #eb234a;
  border: 1px solid #EB234A;
}

.image-gallery-bullets .image-gallery-bullet {
  background-color: rgba(253, 233, 236, 0.5);
  border: 1px solid rgba(253, 233, 236, 0.5);
}

.scrollToTopClass {
  border: 2px solid #f48247 !important;
  background-color: #f48247 !important;
  color: white;
  font-size: 2rem;
  z-index: 15;
  border-radius: 50% !important;
  height: 5rem;
  width: 5rem;
  bottom: 10% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.outerBox {
  border: 1px solid #E9EDEF;
  padding: 1rem;
  margin-bottom: 1rem;
}

.imageDiv {
  background-size: cover;
  background-position: center center;
  height: 20vh;
}

.bookingModal {
  padding-top: 0;
  padding-bottom: 0;
}

.bookingModal .bookingModalInfo {
  margin: 1rem;
  margin-top: 0;
}

.bookingModal .flexcol{
  display: flex;
  flex-direction: column;
}

.bookingModal .flexSpWrap {
  
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: -1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
@media screen and (min-width: 776px) {
  .AccommodationDetailsDesktop .acccontainer {
    width: 75vw;
    margin: auto;
  }
  .AccommodationDetails .information {
    width: 60vw;
    margin: auto;
    margin-top: 2rem;
    padding: 0;
  }
  .AccommodationDetails .mainHeading {
    font-weight: bold;
    font-size: 3rem;
  }
  .AccommodationDetails .subHeading {
    font-size: 2.3rem;
    margin: 2rem 0;
  }
  .image-gallery {
    width: auto;
    height: 75vh;
  }
  .image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: 65vh;
  }
  .ant-calendar-picker-icon {
    display: none;
  }
  .sideImage {
    height: calc( (60vh - 1rem) / 3);
    width: 18vw;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }
  .mainImage {
    height: 60vh;
    width: calc(57vw - 0.5rem);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }
  .imageOverlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    cursor: pointer;
  }
}