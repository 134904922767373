.Home .Hero {
    height: 65vh;
    width: 100vw;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom right;
    background-image: url("./../../images/Home/Hero2.jpeg");
    background-position-y : 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Home .HeroCenter {
    width: 75vw;
    margin: auto;
    margin-bottom : 35px;
}

.Home .HeroText {
    font-size: 3rem;
    width: 50vw;
    font-weight: 700;
    color: black;
    height: 22vh;
    margin-bottom : 45px;
}

.Home .HeroTextBottom {
    font-weight: 700;
    margin-top: 0.5rem;
    color: white;
}

.Home .PopularDestination {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
}

.Home .smallImage {
    height: 18vw;
    width: 18vw;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
}

.Home .largeImage {
    height: 24vw;
    width: 37vw;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
}

.PopularDestination .text {
    font-weight: 500;
    margin-top: 1rem;
    cursor: pointer;
}

.PopularDestination .subText {
    font-weight: 400;
    color: #858586;
    cursor: pointer;
}

.Home .Row {
    width: 75vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 3rem auto;
    margin-bottom: 5rem;
    flex-wrap: wrap;
}

.Home .homeHeading {
    font-weight: 700;
    width: 75vw;
    margin: 0 auto;
    font-size: 2.5rem;
    margin-top: 3rem;
}

.HomeMobile .PopularDestination {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
}

.HomeMobile .Header {
    height: auto;
}

.carouselContainer {
    height: 80vw;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.HomeMobile .smallImage {
    height: 60vw;
    width: 60vw;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.carouselContainerDots {
    background-color: rgba(235, 35, 74, 0.1);
    border-radius: 5rem;
    margin: 1rem;
    height: 1rem;
    width: 1rem;
}

.HomeMobile .ant-carousel .slick-dots>li>button {
    background-color: rgba(235, 35, 74, 0.3);
    border-radius: 5rem;
    margin: 1rem;
    height: 1rem;
    width: 1rem;
}

.HomeMobile .ant-carousel .slick-dots .slick-active>button {
    background: #eb234a;
}

.HomeMobile .homeHeading {
    font-weight: 700;
    padding: 1rem 1.75rem;
    width: 70vw;
    font-size: 2rem;
    /*margin-top: 2.5rem;*/
}

.HomeMobile .mobileImage {
    height: 60vw;
    width: calc(100vw - 3.5rem);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}
